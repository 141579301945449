// BACKUP: When something wrong
// const slug = /^[A-Za-z0-9-]*$/;

// English, number, -
// one---two-thee- (more than one consequence '-' NOT allow)
// -one-two-three (start with '-' NOT allow)
// one-two-three- (end with '-' NOT allow)
// ONE-two (have uppercase NOT allow)
const slug = /^[a-z0-9-]+(?:-[a-z0-9-]+)*$/;

export default slug;
