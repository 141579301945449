<template>
	<div class="main-wrapper mt-4">
		<CRow>
			<CCol
				md="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4>General info</h4>
				<div class="marketing-category-id">
					<span class="color-black-45 mr-1">Marketing campaign ID:</span>
					<span class="color-black-45 font-weight-bold">
						{{ isEdit ? $route.params.id : 'Generate after saving' }}
					</span>
				</div>
			</CCol>
			<CCol md="6">
				<CInput
					v-model="formData.generalInfo.nameEn"
					:is-valid="!$v.formData.generalInfo.nameEn.$error && null"
					label="Marketing campaign name (EN)*"
					:invalid-feedback="$t('global.error.required')"
					@blur="handleAutoFillMetaTitleAndSlug"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model="formData.generalInfo.nameTh"
					:is-valid="!$v.formData.generalInfo.nameTh.$error && null"
					label="Marketing campaign name (TH)*"
					:invalid-feedback="$t('global.error.required')"
				/>
			</CCol>
			<CCol md="12">
				<CInput
					v-model="formData.generalInfo.slug"
					:is-valid="!$v.formData.generalInfo.slug.$error && null"
					:invalid-feedback="slugErrors"
					label="Slug*"
					description="Allow letters; numbers; and ( - )"
				/>
			</CCol>
			<CCol md="12">
				<div class="form-group form-group-switch mt-1">
					<label class="toggle-label" for="status">Marketing campaign status</label>
					<CSwitch
						id="status"
						:checked.sync="formData.generalInfo.isActive"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span>{{ formData.generalInfo.isActive ? 'Active' : 'Inactive' }}</span>
				</div>
			</CCol>
			<CCol md="12">
				<div class="form-group form-group-switch mt-1">
					<label class="toggle-label" for="visibility">Store front visibility</label>
					<CSwitch
						id="visibility"
						:checked.sync="formData.generalInfo.isVisible"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span>{{ formData.generalInfo.isVisible ? 'Show' : 'Hide' }}</span>
				</div>
			</CCol>
			<CCol>
				<hr class="mb-5">
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">Active dates</h4>
			</CCol>
			<CCol md="6">
				<div class="d-flex">
					<BaseInputDate
						v-model="$v.formData.activeDate.startDate.$model"
						:disabled="isDisabledStartDate"
						:is-valid="!$v.formData.activeDate.startDate.$error"
						:min-date="new Date()"
						label="Start date*"
						class="mr-2"
						placeholder="Select date"
						invalid-feedback="Start date is required"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
					<BaseInputTime
						v-model="$v.formData.activeDate.startTime.$model"
						:disabled="isDisabledStartDate"
						:is-valid="!$v.formData.activeDate.startTime.$error"
						:invalid-feedback="startTimeErrors"
						label="Start time*"
					/>
				</div>
			</CCol>
			<CCol md="6">
				<div class="d-flex">
					<BaseInputDate
						v-model="$v.formData.activeDate.endDate.$model"
						:disabled="$v.formData.activeDate.startDate.$invalid || $v.formData.activeDate.startTime.$invalid"
						:is-valid="!$v.formData.activeDate.endDate.$error"
						:min-date="formData.activeDate.startDate"
						label="End date"
						class="mr-2"
						placeholder="Select date"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
					<BaseInputTime
						v-model="$v.formData.activeDate.endTime.$model"
						:disabled="$v.formData.activeDate.startDate.$invalid || $v.formData.activeDate.startTime.$invalid"
						:is-valid="!$v.formData.activeDate.endTime.$error"
						:invalid-feedback="endTimeErrors"
						label="End time"
					/>
				</div>
			</CCol>
			<CCol>
				<hr class="my-5">
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<h4 class="mb-3">Link to Home page</h4>
				<div class="description mb-4">
					You can show this marketing campaign on Homepage by
					selecting the product widget's name below. If there is no
					widget available, please create the product widget on
					<router-link :to="{ name: 'Homepage' }" target="_blank">
						Homepage customization
					</router-link>.
				</div>
			</CCol>
			<CCol md="12">
				<div class="d-flex align-items-start justify-content-between">
					<h4 class="mb-4">Link to</h4>
					<CButton
						color="secondary"
						@click="$refs['modal-widget'].open()"
					>
						<CIcon name="cil-plus" class="mr-2" />
						Add
					</CButton>
				</div>
				<div
					v-if="formData.linkToHomepage.widgets.length"
					class="border border-gray-400 rounded p-3"
				>
					<div
						v-for="widget in formData.linkToHomepage.widgets"
						:key="widget.id"
						class="d-inline-flex align-items-center px-2 d-inline-block typo-body-2 bg-gray-300 rounded mr-3"
					>
						<span class="mr-1">{{ widget.name }}</span>
						<CButton
							variant="ghost"
							class="p-0"
							@click="handleRemoveWidget(widget.id)"
						>
							<CIcon name="cil-x" size="sm" />
						</CButton>
					</div>
				</div>

				<BaseNoItemBanner v-else text="There is no widget linked" />
			</CCol>
			<CCol>
				<hr class="mt-4 mb-5">
			</CCol>
		</CRow>
		<template v-if="isEdit">
			<section>
				<h4 class="mb-4">Filters</h4>
				<p class="typo-body-2 mb-3">{{ $t("page.categories.edit.filterDetail") }}</p>
				<router-link
					:to="{
						name: 'MarketingCampaignAttributeEdit',
						params: {
							id: this.$route.params.id,
						},
					}"
					class="btn btn-secondary"
				>
					Manage filters
				</router-link>
			</section>
			<hr class="my-5">
		</template>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">Contents</h4>
			</CCol>
			<CCol md="12">
				<BaseMediaSelectorBox
					ref="desktopBanner"
					v-model="formData.content.banners[0]"
					title="Marketing campaign banner"
					no-image-text="No category banner"
					show-helper
					width="1752"
					height="640"
					required
					class="mb-4"
				/>
			</CCol>
			<CCol md="12" class="mb-4">
				<label>Marketing campaign description (top)</label>
				<BaseRichTextEditor v-model="formData.content.descriptionTop" />
			</CCol>
			<CCol md="12">
				<label>Marketing campaign description (bottom)</label>
				<BaseRichTextEditor v-model="formData.content.descriptionBottom" />
			</CCol>
			<CCol>
				<hr class="mt-4 mb-5">
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">SEO</h4>
			</CCol>
			<CCol md="6" class="mb-4">
				<CTextarea
					v-model="formData.seo.titleEn"
					label="Meta title (EN)"
					rows="2"
				/>
			</CCol>
			<CCol md="6" class="mb-4">
				<CTextarea
					v-model="formData.seo.titleTh"
					label="Meta title (TH)"
					rows="2"
				/>
			</CCol>
			<CCol md="6" class="mb-4">
				<CTextarea
					v-model="formData.seo.keywordEn"
					label="Meta keywords (EN)"
					rows="3"
				/>
			</CCol>
			<CCol md="6" class="mb-4">
				<CTextarea
					v-model="formData.seo.keywordTh"
					label="Meta keywords (TH)"
					rows="3"
				/>
			</CCol>
			<CCol md="6" class="mb-4">
				<CTextarea
					v-model="formData.seo.descriptionEn"
					label="Meta description (EN)"
					rows="6"
				/>
			</CCol>
			<CCol md="6" class="mb-4">
				<CTextarea
					v-model="formData.seo.descriptionTh"
					label="Meta description (TH)"
					rows="6"
				/>
			</CCol>
			<CCol>
				<hr>
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:is-edit="isEdit"
			:remove-text="isEdit ? 'Remove marketing campaign' : null"
			:disabled-confirm="isSubmitting"
			@onCancel="handleCancel"
			@onConfirm="handleSubmit"
			@onRemove="handleRemove"
		/>
		<ModalWidget
			ref="modal-widget"
			:list="formData.linkToHomepage.widgets"
			:widget-type="WIDGET_TYPE.products"
			@onConfirm="handleConfirmWidget"
		/>
		<BaseModalConfirm
			ref="modal-override-widget"
			title="Override the linked marketing campaign?"
			description="There's a marketing campaign linked to this widget, click 'Confirm' to override this new marketing campaign or cancel to select other widgets."
			@onConfirm="handleOverrideWidget"
		/>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import slugify from 'slugify';
import { required, requiredIf, helpers } from 'vuelidate/lib/validators';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import ModalWidget from '@/components/ModalWidget.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import slugRegex from '../regex/slug';
import { timeFormat } from '../assets/js/validators';
import { scrollToTop } from '../assets/js/helpers';
import { MARKETING_CAMPAIGN_PERIOD_VALUE } from '../enums/marketings';
import { WIDGET_TYPE } from '../enums/widgets';
import { checkWidgetsAPI } from '../services/api/widgets.api';

export default {
	name: 'FormMarketingCampaignGeneralInfo',
	components: {
		BaseMediaSelectorBox,
		BaseModalConfirm,
		ModalWidget,
	},
	validations: {
		formData: {
			generalInfo: {
				nameEn: {
					required,
				},
				nameTh: {
					required,
				},
				slug: {
					required,
					slugFormat: helpers.regex('slug', slugRegex),
				},
			},
			activeDate: {
				startDate: {
					required,
				},
				startTime: {
					required,
					timeFormat,
				},
				endDate: {},
				endTime: {
					required: requiredIf('endDate'),
					timeFormat,
					endAfter(value, vm) {
						if (vm.startDate && vm.endDate && dayjs(vm.startDate).isSame(dayjs(vm.endDate))) {
							const [timeFromHour = 0, timeFromMinute = 0] = vm.startTime.split(':');
							const [timeToHour = 0, timeToMinute = 0] = vm.endTime.split(':');
							const timeFrom = dayjs().hour(timeFromHour).minute(timeFromMinute);
							const timeTo = dayjs().hour(timeToHour).minute(timeToMinute);
							return timeFrom.isBefore(timeTo);
						}
						return true;
					},
				},
			},
		},
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		periodStatus: {
			type: String,
			default: null,
			validator(value) {
				return [
					MARKETING_CAMPAIGN_PERIOD_VALUE.SCHEDULE,
					MARKETING_CAMPAIGN_PERIOD_VALUE.ONGOING,
					MARKETING_CAMPAIGN_PERIOD_VALUE.EXPIRED,
				].indexOf(value) !== -1;
			},
		},
		nameEn: {
			type: String,
			default: null,
		},
		nameTh: {
			type: String,
			default: null,
		},
		slug: {
			type: String,
			default: null,
		},
		isActive: {
			type: Boolean,
			default: null,
		},
		isVisible: {
			type: Boolean,
			default: null,
		},
		startDate: {
			type: Date,
			default: null,
		},
		startTime: {
			type: String,
			default: '00:00',
		},
		endDate: {
			type: Date,
			default: null,
		},
		endTime: {
			type: String,
			default: '23:59',
		},
		widgets: {
			type: Array,
			default: () => [],
		},
		banners: {
			type: Array,
			default: () => [],
		},
		descriptionTop: {
			type: String,
			default: null,
		},
		descriptionBottom: {
			type: String,
			default: null,
		},
		titleEn: {
			type: String,
			default: null,
		},
		titleTh: {
			type: String,
			default: null,
		},
		keywordEn: {
			type: String,
			default: null,
		},
		keywordTh: {
			type: String,
			default: null,
		},
		descriptionEn: {
			type: String,
			default: null,
		},
		descriptionTh: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			WIDGET_TYPE,
			selectedWidgets: [],
			formData: {
				generalInfo: {
					nameEn: null,
					nameTh: null,
					slug: null,
					isActive: false,
					isVisible: false,
				},
				activeDate: {
					startDate: null,
					startTime: null,
					endDate: null,
					endTime: null,
				},
				linkToHomepage: {
					widgets: [],
				},
				content: {
					banners: [],
					descriptionTop: null,
					descriptionBottom: null,
				},
				seo: {
					titleEn: null,
					titleTh: null,
					keywordEn: null,
					keywordTh: null,
					descriptionEn: null,
					descriptionTh: null,
				},
			},
		};
	},
	computed: {
		isDisabledStartDate() {
			return this.isEdit && [
				MARKETING_CAMPAIGN_PERIOD_VALUE.ONGOING,
				MARKETING_CAMPAIGN_PERIOD_VALUE.EXPIRED,
			].includes(this.periodStatus);
		},
		slugErrors() {
			if (!this.$v.formData.generalInfo.slug.required) {
				return 'Required field';
			}

			if (!this.$v.formData.generalInfo.slug.slugFormat) {
				return 'Invalid slug format';
			}

			return null;
		},
		startTimeErrors() {
			if (!this.$v.formData.activeDate.startTime.required) {
				return 'Required field';
			}

			if (!this.$v.formData.activeDate.startTime.timeFormat) {
				return 'Invalid time format';
			}

			return null;
		},
		endTimeErrors() {
			if (!this.$v.formData.activeDate.endTime.required) {
				return 'Required when end date assigned';
			}

			if (!this.$v.formData.activeDate.endTime.timeFormat) {
				return 'Invalid time format';
			}

			if (!this.$v.formData.activeDate.endTime.endAfter) {
				return `Should be after ${this.formData.activeDate.startTime}`;
			}

			return null;
		},
	},
	created() {
		this.formData = {
			generalInfo: {
				nameEn: this.nameEn,
				nameTh: this.nameTh,
				slug: this.slug,
				isActive: this.isActive,
				isVisible: this.isVisible,
			},
			activeDate: {
				startDate: this.startDate,
				startTime: this.startTime,
				endDate: this.endDate,
				endTime: this.endTime,
			},
			linkToHomepage: {
				widgets: this.widgets,
			},
			content: {
				banners: this.banners,
				descriptionTop: this.descriptionTop,
				descriptionBottom: this.descriptionBottom,
			},
			seo: {
				titleEn: this.titleEn,
				titleTh: this.titleTh,
				keywordEn: this.keywordEn,
				keywordTh: this.keywordTh,
				descriptionEn: this.descriptionEn,
				descriptionTh: this.descriptionTh,
			},
		};
	},
	methods: {
		handleAutoFillMetaTitleAndSlug() {
			if (!this.formData.generalInfo.slug) {
				this.formData.generalInfo.slug = slugify(
					this.formData.generalInfo.nameEn,
					{
						lower: true,
						strict: true,
					},
				);
				this.$v.formData.generalInfo.slug.$touch();
			}
		},
		async handleConfirmWidget(widgets) {
			const ids = widgets.map((widget) => widget.id);
			const { data } = await checkWidgetsAPI(ids);
			const hasOverrideWidget = data?.data.length;
			this.selectedWidgets = widgets;
			if (hasOverrideWidget) {
				this.$refs['modal-override-widget'].open();
			} else {
				this.formData.linkToHomepage.widgets = this.selectedWidgets;
			}
		},
		handleOverrideWidget() {
			this.formData.linkToHomepage.widgets = this.selectedWidgets;
		},
		handleRemoveWidget(widgetId) {
			this.formData.linkToHomepage.widgets = this.formData.linkToHomepage.widgets.filter((widget) => widget.id !== widgetId);
		},
		handleCancel() {
			this.$router.push({ name: 'MarketingCampaignList' });
		},
		handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}
			this.$emit('onConfirm', this.formData);
		},
		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
.toggle-label {
	width: rem(170);
}

.marketing-category-id {
	font-size: rem($font-size-caption);
}
</style>
